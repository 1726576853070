
const playSoundAnchor = document.getElementById('playStrichSoundAnchor');
if (playSoundAnchor) {
    // See: https://parceljs.org/getting-started/migration/#importing-non-code-assets-from-javascript
    const soundUrl = new URL('../assets/sounds/strich.mp3', import.meta.url);
    playSoundAnchor.addEventListener('click', function (evt) {
        new Audio(soundUrl).play();
        evt.preventDefault();
    });
}
